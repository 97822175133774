export const TM_MODEL_URL = process.env.REACT_APP_TM_MODEL_URL + "model.json";
export const TM_METADATA_URL =
  process.env.REACT_APP_TM_MODEL_URL + "metadata.json";

export const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
export const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

export const FRAMES_PER_SECOND = 2;

export const GESTURES = ["rock", "paper", "scissors", "lizard", "spock"];

export const IDLE_TIMEOUT = 20000;

export const NAME_OF_FAIR = "Facts";
