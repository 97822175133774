import React, { useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./TogglePlayComponent.css";

export const TogglePlayComponent = ({ onCheckPlayChange }) => {
  // keep class for parent element that stored the check state
  const [checked, setChecked] = useState(false);

  // the user can indicate if the game has started by checking the checkbox
  return (
    // the label element is used to wrap the checkbox and the text
    // add the checked class to the label element if the checkbox is checked

    <label className={"TogglePlayComponent checked-" + checked}>
      <Toggle
        defaultChecked={false}
        onChange={(e) => {
          onCheckPlayChange(e.target.checked);
          setChecked(e.target.checked);
        }}
      />
      <span>Supasync</span>
    </label>
  );
};
