import "./CamSelectComponent.css";
import React, { useEffect, useState, useCallback } from "react";

export const CamSelectComponent = ({ onCamChange, webcamRef }) => {
  const [devices, setDevices] = useState([]);
  const [className, setClassName] = useState("noCam");

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  const handleCamChange = useCallback(
    (e) => {
      setClassName("hasCam");
      onCamChange(e);
    },
    [onCamChange]
  );

  useEffect(() => {
    // ask for permission to use the webcam
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(function (stream) {
        // get the list of available devices
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
      })
      .catch(function (err) {
        console.log("GUM failed with error, time diff");
      });
  }, [handleDevices]);

  // return a select element with options for each device
  return (
    <div className="CamSelectComponent">
      <select onChangeCapture={handleCamChange} className={className}>
        <option value="">Selecteer webcam...</option>
        {devices.map((device, key) => (
          <option key={key} value={device.deviceId}>
            {device.label}
          </option>
        ))}
      </select>
    </div>
  );
};
