import "./GestureIconComponent.css";
import React, { useEffect, useState } from "react";

export const GestureIconComponent = ({ gesture, className, title }) => {
  // state for gesture icon
  const [icon, setIcon] = useState("rock.png");

  // set icon
  useEffect(() => {
    setIcon("/icons/" + gesture + ".svg");
  }, [gesture]);

  // return icon
  return (
    <>
      <div className={className}>
        <div className="icon">
          <img src={icon} alt={gesture} />
        </div>
        <h2>{title}</h2>
      </div>
    </>
  );
};
