import * as tmImage from "@teachablemachine/image";
import { TM_MODEL_URL, TM_METADATA_URL } from "./constants.js";

// load teachable machine model
export const loadModel = async (deviceId) => {
  // load the model and metadata
  const model = await tmImage.load(TM_MODEL_URL, TM_METADATA_URL);
  const maxPredictions = model.getTotalClasses();

  // convenience function to setup a webcam
  const webcam = new tmImage.Webcam(224, 224, true); // width, height, flip
  await webcam.setup({ deviceId }); // request access to the webcam
  await webcam.play();

  return { model, maxPredictions, webcam };
};

// run the webcam image through the image model
export const predict = async ({ model, webcam, maxPredictions }) => {
  // console.log("Predicting...");
  // predict can take in an image, video or canvas html element
  const predictions = await model.predict(webcam.canvas);
  const result = new Map();
  [...new Array(maxPredictions)].forEach((item, index) => {
    const { className } = predictions[index];
    const probability = parseFloat(predictions[index].probability.toFixed(2));
    result.set(className, probability);
  });

  // get max prediction
  const max = [...result.entries()].reduce((acc, cur) =>
    cur[1] > acc[1] ? cur : acc
  );

  return {
    prediction: {
      icon: max[0],
      probability: max[1],
    },
    classes: [...result.keys()],
  };
};
