import "./BtnChallengeComponent.css";
import React, { useEffect } from "react";

export const BtnChallengeComponent = ({ isLoading, show, onClick }) => {
  const className = "BtnChallengeComponent " + (show ? "show" : "hide");

  const handler = (e) => {
    console.log(e);
    if (e.key === " " && show) {
      onClick();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => window.removeEventListener("keydown", handler, false);
  }, []);

  // return the challenge button, which is only visible when the game has started
  return (
    <div className={className}>
      <button onClick={onClick} style={{ display: show ? "block" : "none" }}>
        Challenge
      </button>
    </div>
  );
};
