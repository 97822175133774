import "./App.css"; // import css
import React, { useEffect, useState, useRef, useCallback } from "react"; // import react

// import components
import { WebcamComponent } from "./Components/Webcam/WebcamComponent";
import { CamSelectComponent } from "./Components/CamSelect/CamSelectComponent";
import { GestureIconComponent } from "./Components/GestureIcon/GestureIconComponent";
import { TogglePlayComponent } from "./Components/TogglePlay/TogglePlayComponent";
import { BtnChallengeComponent } from "./Components/BtnChallenge/BtnChallengeComponent";
import { ChallengeComponent } from "./Components/Challenge/ChallengeComponent";
import { LivesComponent } from "./Components/Lives/LivesComponent";

// import constants
import { FRAMES_PER_SECOND, IDLE_TIMEOUT } from "./lib/constants.js";

// import helper functions
import {
  syncSupaGesture,
  syncSupaPlayStatus,
  syncSupaHandInBox,
  suncSupaGameWinner,
  storeNewPlayer,
  updatePlayerScore,
} from "./lib/SupabaseHelper";
import { loadModel, predict } from "./lib/TeachableMachineHelper";
import { winnerCalculator, generateGesture } from "./lib/GamePlayHelper";
import { InputPlayerComponent } from "./Components/InputPlayer/InputPlayerComponent";

function App() {
  // states
  const [selectedCam, setSelectedCam] = useState(null);
  const [gesture, setGesture] = useState("cross-mark");
  const [aiGesture, setAiGesture] = useState("ai");
  const [handInBox, setHandInBox] = useState(false);
  const [tmParams, setTmParams] = useState({
    model: null, // teachable machine model
    webcam: null, // webcam object
    maxPredictions: null, // max predictions
  });

  const [supaSyncEnabled, setSupaSyncEnabled] = useState(false);
  const [challengeNr, setChallengeNr] = useState(0);
  const [gameWinner, setGameWinner] = useState(null);
  const [namePlayer, setNamePlayer] = useState(null);
  const [namePlayerError, setNamePlayerError] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [counterWins, setCounterWins] = useState(0);
  const [lives, setLives] = useState(3);

  // keep track of an active challenge
  const activeChallenge = useRef(false);
  const timeoutRef = useRef(null);

  // constants
  const webcamRef = useRef(null);

  // handle camera change
  const handleCamChange = (e) => {
    const el = e.target;
    const deviceId = el.value;
    const label = el.options[el.selectedIndex].innerHTML;
    setSelectedCam(deviceId);
    console.log("Selected cam: ", label);
  };

  const handleNewPlayer = (playerName) => {
    setNamePlayer(playerName);
    setLives(3);
  };

  // handle game play change
  const handleGamePlayChange = (checked) => {
    setSupaSyncEnabled(checked);
    console.log("Connected to Supabase: ", checked);
  };

  // handle challenge button click
  const handleChallengeClick = async () => {
    // set active challenge to true, so the gesture is not overwritten
    setChallengeNr(challengeNr + 1);
    activeChallenge.current = true;

    // predict gesture and set gesture state
    const result = await predict(tmParams);
    setGesture(result.prediction.icon);

    // if no hands in box, set ai icon to ai-icon
    if (result.prediction.icon === "cross-mark") {
      setAiGesture("ai");
    } else {
      // generator ai gesture and set ai icon
      setAiGesture(generateGesture());
    }

    // set active challenge to false after 2 seconds
    setTimeout(() => {
      activeChallenge.current = false;
    }, 2000);
  };

  // loop function
  const loop = useCallback(async () => {
    // update the webcam frame every loop iteration
    tmParams.webcam.update();

    const result = await predict(tmParams);

    // check if hand is in box
    if (result.prediction.icon !== "cross-mark") {
      console.log("Hand in box", result.prediction.icon);
      setHandInBox(true);

      // set gesture state to human if no active challenge
      if (!activeChallenge.current) {
        setGesture("human");
        setAiGesture("ai");
      }
    } else {
      console.log("No hand in box");
      setHandInBox(false);
      setGesture("cross-mark");
    }

    // wait some time before looping again, so we don't overload the browser
    setTimeout(() => {
      window.requestAnimationFrame(loop);
    }, 1000 / FRAMES_PER_SECOND);
  }, [tmParams]);

  const idleTimeout = useCallback(() => {
    console.log(
      "Idle timeout started, counting down from " + IDLE_TIMEOUT + "ms"
    );
    // show input field after 5 seconds of inactivity
    timeoutRef.current = setTimeout(() => {
      gameEnd();
    }, IDLE_TIMEOUT);
  }, [timeoutRef]);

  // update game winner
  useEffect(() => {
    const winner = winnerCalculator(gesture, aiGesture);
    if (winner?.result === "human") {
      setCounterWins(counterWins + 1);
    } else if (winner?.result === "ai") {
      setLives(lives - 1);
    }
    setGameWinner(winner);
  }, [gesture, aiGesture]);

  const handleUserKeyPress = useCallback((event) => {
    const { key } = event;
    if (key === "Escape") {
      gameEnd();
    }
  }, []);

  const gameEnd = useCallback(() => {
    // update score in supabase
    updatePlayerScore(namePlayer, counterWins);
    setNamePlayer(null);
    setShowInput(true);
    setCounterWins(0);
    setLives(3);
    clearTimeout(timeoutRef.current);
  }, [namePlayer, counterWins, timeoutRef]);

  useEffect(() => {
    if (lives === 0) {
      setTimeout(() => {
        gameEnd();
      }, 2000);
    }
  }, [lives, setNamePlayer, setShowInput, timeoutRef]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  /** check inactivity */
  useEffect(() => {
    if (namePlayer && !handInBox && !showInput && selectedCam) {
      idleTimeout();
    } else {
      clearTimeout(timeoutRef.current);
    }
  }, [namePlayer, handInBox, idleTimeout, showInput, selectedCam]);

  /********** SUPABASE SYNC FUNCTIONS  ****************/

  // sync game started in supabase
  useEffect(() => {
    if (!supaSyncEnabled) return;
    syncSupaPlayStatus(supaSyncEnabled);
  }, [supaSyncEnabled]);

  // sync hand in box in supabase
  useEffect(() => {
    if (!supaSyncEnabled) return;
    syncSupaHandInBox(handInBox);
  }, [handInBox, supaSyncEnabled]);

  // sync human gesture in supabase
  useEffect(() => {
    if (!supaSyncEnabled) return;
    syncSupaGesture(gesture);
  }, [gesture, supaSyncEnabled]);

  // update game winner + sync in supabase
  useEffect(() => {
    if (!supaSyncEnabled) return;
    suncSupaGameWinner(gameWinner?.result || "pending", namePlayer || null);
  }, [gameWinner, supaSyncEnabled]);

  // store new player in supabase
  useEffect(() => {
    if (!supaSyncEnabled && namePlayer) {
      setNamePlayerError(false);
      setShowInput(false);
      return;
    }
    storeNewPlayer(namePlayer)
      .then((response) => {
        if (!response) return;
        if (!response.error) {
          setNamePlayerError(false);
          // hide input field
          setShowInput(false);
          // } else if (response?.error?.code === "23505") {
        } else {
          setNamePlayer(null);
          setNamePlayerError(true);
          setTimeout(() => {
            setNamePlayerError(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [namePlayer, supaSyncEnabled]);

  // start the webcam loop when model is loaded
  useEffect(() => {
    if (!tmParams.model || !tmParams.webcam || !tmParams.maxPredictions) {
      return;
    }

    window.requestAnimationFrame(loop);
  }, [tmParams, loop]);

  // load model when camera is selected
  useEffect(() => {
    // return if no camera is selected
    if (!selectedCam) return;

    // load model, when loaded set states
    loadModel(selectedCam).then((modelProps) => {
      // set model, webcam and max predictions
      setTmParams(modelProps);
    });
  }, [selectedCam]);

  return (
    <div className="App">
      <h3 className="playerName">
        {showInput ? "human" : namePlayer}{" "}
        <span className="counterWins">{counterWins ?? 0}</span>
      </h3>
      <LivesComponent lives={lives} />
      <WebcamComponent device={selectedCam} webcamRef={webcamRef} />
      <GestureIconComponent
        gesture={gesture}
        className="GestureIconComponent human"
        title="Human"
      />
      <GestureIconComponent
        gesture={aiGesture}
        className="GestureIconComponent ai"
        title="AI"
      />
      <ChallengeComponent challengeNr={challengeNr} gameResult={gameWinner} />
      {!selectedCam ? (
        <CamSelectComponent
          onCamChange={(e) => {
            handleCamChange(e);
          }}
          webcamRef={webcamRef}
        />
      ) : (
        ""
      )}
      {tmParams.model &&
      tmParams.webcam &&
      tmParams.maxPredictions &&
      handInBox &&
      !showInput &&
      !activeChallenge.current ? (
        <BtnChallengeComponent
          show={tmParams.model && tmParams.webcam && tmParams.maxPredictions}
          onClick={handleChallengeClick}
        />
      ) : (
        ""
      )}
      {/* <KeyboardComponent /> */}
      <InputPlayerComponent
        showInput={showInput}
        onConfirm={handleNewPlayer}
        error={namePlayerError}
      />
      <TogglePlayComponent onCheckPlayChange={handleGamePlayChange} />
    </div>
  );
}

export default App;
