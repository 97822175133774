import { createClient } from "@supabase/supabase-js";
import { SUPABASE_URL, SUPABASE_KEY } from "../lib/constants.js";

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

// update gesture in supabase
export const syncSupaGesture = async (gesture) => {
  console.log("Syncing gesture to Supabase: ", gesture);

  await supabase
    .from("settings_game")
    .update({ value_plain: gesture, updated_at: new Date() })
    .eq("key", "gesture");
};

export const syncSupaPlayStatus = async (gameStarted) => {
  console.log("Syncing game play status to Supabase: ", gameStarted);

  await supabase
    .from("settings_game")
    .update({ value_plain: gameStarted ? 1 : 0, updated_at: new Date() })
    .eq("key", "isPlaying");
};

export const syncSupaHandInBox = async (handInBox) => {
  console.log("Syncing hand in box status to Supabase: ", handInBox);

  await supabase
    .from("settings_game")
    .update({ value_plain: handInBox ? 1 : 0, updated_at: new Date() })
    .eq("key", "handInBox");
};

export const suncSupaGameWinner = async (winner, namePlayer) => {
  if (!namePlayer) return;

  namePlayer = namePlayer.toLowerCase();

  console.log("Syncing game winner to Supabase: ", winner, namePlayer);

  await supabase
    .from("settings_game")
    .update({ value_plain: winner, updated_at: new Date() })
    .eq("key", "winner");
};

// store new player in database
export const storeNewPlayer = async (player) => {
  if (!player || player === "") {
    return {
      error: "No player name",
      message: "Please use a different name",
    };
  }

  // make lowercase
  player = player.toLowerCase();

  console.log("Storing new player in Supabase: ", player);
  const response = await supabase.from("players").insert({
    name: player,
  });

  if (response.error) {
    console.log("Error storing player: ", response.error);

    return {
      error: response.error,
      message: "Please use a different name",
    };
  }

  return {
    error: null,
    message: "Player stored",
  };
};

export const updatePlayerScore = async (player, score) => {
  if (!player || player === "") return;
  // make lowercase
  player = player.toLowerCase();

  console.log("Updating player score in Supabase: ", player, score);
  const response = await supabase
    .from("players")
    .update({ score: score })
    .eq("name", player);

  if (response.error) {
    console.log("Error updating player score: ", response.error);
    return {
      error: response.error,
      message: "Error updating player score",
    };
  }

  return {
    error: null,
    message: "Player score updated",
  };
};
