import React, { useEffect, useState, useRef } from "react";
import "./ChallengeComponent.css";

/** this componnent will countdown from three to 1 */
export const ChallengeComponent = ({ challengeNr, gameResult }) => {
  const [className, setClassName] = useState("ChallengeComponent hide");

  // store last challenge nr
  const lastChallengeNr = useRef(challengeNr);

  // countdown from three to 1, only if new challenge has begun
  useEffect(() => {
    // return if challenge has not changed
    if (lastChallengeNr.current === challengeNr) return;

    // update last challenge nr
    lastChallengeNr.current = challengeNr;

    setClassName("ChallengeComponent show");
  }, [challengeNr]);

  // return the countdown timer, which is only visible when the challenge has begun
  return (
    <div className={className}>
      <h1 dangerouslySetInnerHTML={{ __html: gameResult?.message }} />
    </div>
  );
};
