import React, { useEffect, useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";

// Instead of the default import, you can also use this:
// import { KeyboardReact as Keyboard } from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";
import "./InputPlayerComponent.css";

export const InputPlayerComponent = ({
  onConfirm,
  showInput,
  error = false,
}) => {
  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");

  // focus on showinput
  const inputRef = useRef(null);
  useEffect(() => {
    if (showInput) {
      inputRef.current.focus();
    }
  }, [showInput]);

  const onChangeInput = (event) => {
    const input = event.target.value;
    setInput(input);
  };
  const onKeyDownInput = (event) => {
    const key = event.nativeEvent.key;
    if (key === " ") {
      // prevent space
      event.preventDefault();
    }
    if (key === "Enter") {
      // validate
      if (input.length < 3 || input.length > 15) {
        return;
      }
      onConfirm(input);
      // make input empty
      setInput("");
    }
  };

  return (
    <div className={"InputPlayerComponent show-" + showInput}>
      <input
        ref={inputRef}
        className={error ? "error" : ""}
        value={input}
        placeholder={"enter your nickname..."}
        onChange={onChangeInput}
        onKeyDown={onKeyDownInput}
      />
    </div>
  );
};
