import React, { useState } from "react";
import "./LivesComponent.css";

export const LivesComponent = ({ lives }) => {
  const heartsArray = [];
  for (let i = 0; i < lives; i++) {
    heartsArray.push(
      <span className="heart" key={i}>
        💛
      </span>
    );
  }

  // the user can indicate if the game has started by checking the checkbox
  return (
    // the label element is used to wrap the checkbox and the text
    // add the checked class to the label element if the checkbox is checked

    // loop over the amount of lives and create a heart for each life with foreach

    <div className="LivesComponent">
      <div className="hearts">{heartsArray}</div>
    </div>
  );
};
